import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, TextField, FormControl } from "@mui/material";
import { Add, TrendingUp } from "@mui/icons-material";
import { toast } from "react-toastify";
import { auth } from "lib/firebase/firebase";
import { BASE_URL, PUBLIC_RAZORPAY_API_KEY } from "lib/utilities/globalConstants";
import { UserAuth } from "provider/AuthProvider";

const AddCoins = () => {
  const { user, wallet, coinPrice, setWallet } = UserAuth();
  const [open, setOpen] = useState(false);
  const [coins, setCoins] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const quickCoins = [500, 1000, 2000, 5000];

  useEffect(() => {
    const loadRazorpay = async () => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      document.body.appendChild(script);
    };
    loadRazorpay();
  }, []);

  const calculatePrice = (coins) => coins * coinPrice;

  const handlePayment = async () => {
    if (!coins || coins <= 0) {
      return toast.error("Please enter a valid number of coins");
    }

    setIsLoading(true);
    try {
      const orderData = {
        uid: user.uid,
        email: user.email,
        name: user.displayName,
        coins: parseInt(coins),
      };

      const res = await fetch(`${BASE_URL}admin/createOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
        },
        body: JSON.stringify(orderData),
      });

      const order = await res.json();

      if (!order.success) {
        return toast.error("Failed to create order");
      }

      const options = {
        key: PUBLIC_RAZORPAY_API_KEY,
        name: "Neusort",
        order_id: order.data.order_id,
        amount: order.data.amount,
        currency: order.data.currency,
        theme: { color: "#3B82F6" },
        prefill: {
          email: orderData.email,
          name: orderData.name,
        },
        handler: async (response) => {
          try {
            setIsLoading(true);
            const verifyRes = await fetch(`${BASE_URL}admin/verifyOrder`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
              },
              body: JSON.stringify({
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
              }),
            });

            const verifiedOrder = await verifyRes.json();
            if (verifiedOrder.success) {
              setWallet((prev) => ({ ...prev, Coins: verifiedOrder.data.companyData.numberOfCoins }));
              toast.success(`Payment successful! Added ${coins} coins to your wallet`); 
              setOpen(false);
            } else {
              toast.error("Payment verification failed");
            }
          } catch (error) {
            console.error(error);
            toast.error("Payment verification failed");
          } finally {
            setIsLoading(false);
          }
        },
        retry: {
          enabled: false,
        },
      };

      const paymentObject = new window.Razorpay(options);

      paymentObject.on("payment.failed", async (response) => {
        toast.error("Payment failed. Please try again.");
        try {
          await fetch(`${BASE_URL}admin/paymentFailed`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
            },
            body: JSON.stringify({ error: response, order_id: order.data.order_id }),
          });
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
          paymentObject.close();
        }
      });

      paymentObject.open();

    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCoinsChange = (value) => {
    const intValue = parseInt(value);
    if (!value) {
      setCoins("");
    } else if (Number.isInteger(intValue) && intValue > 0) {
      setCoins(intValue.toString());
    }
  };

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        className="bg-blue-50 border text-blue-700 border-blue-200 hover:bg-blue-100 px-4 py-1.5 flex items-center rounded-lg transition-colors duration-150 text-sm font-medium w-[112px]"
      >
        <Add style={{ width: "16px", height: "16px" }} />
        <span className="ml-2">Add Coins</span>
      </button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          className: "rounded-xl",
        }}
      >
        <div className="flex flex-col bg-white">
          <div className="relative p-4 bg-gradient-to-r from-blue-600 to-indigo-600 text-white rounded-b-2xl">
            <div className="text-center">
              <p className="text-white/90 text-xs font-medium tracking-wide">WALLET BALANCE</p>
              <h1 className="text-3xl font-bold">{wallet?.Coins || 0}</h1>
              <div className="inline-block px-3 py-1 bg-white/10 rounded-full text-xs">
                ₹{coinPrice} per coin
              </div>
            </div>
          </div>

          <DialogContent className="p-4">
            <div className="flex flex-col gap-4 -mt-2">
              <div className="bg-gray-50 rounded-xl border border-gray-200 flex flex-col">
                <div className="p-4">
                  <div className="flex items-center justify-between mb-3">
                    <p className="text-xs font-semibold text-gray-700">Quick Add</p>
                    <TrendingUp className="text-blue-500 w-4 h-4" />
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    {quickCoins.map((amount) => (
                      <button
                        key={amount}
                        onClick={() => handleCoinsChange(amount)}
                        className={`p-3 rounded-xl transition-colors ${coins === amount.toString()
                          ? "bg-gradient-to-r from-blue-500 to-indigo-600 text-white"
                          : "bg-white border border-gray-100 text-gray-700 hover:bg-gray-50"
                          }`}
                      >
                        <div className="text-center">
                          <div className="text-lg font-bold">
                            {amount} <span className="text-sm font-medium">Coins</span>
                          </div>
                          <div
                            className={`text-xs ${coins === amount.toString() ? "text-white/90" : "text-gray-500"
                              }`}
                          >
                            ₹{calculatePrice(amount)}
                          </div>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
                <div className="border-t border-gray-200 p-4">
                  <div className="flex items-center justify-between mb-3">
                    <p className="text-xs font-semibold text-gray-700">Custom Coins</p>
                  </div>
                  <FormControl fullWidth>
                    <TextField
                      placeholder="Enter number of coins"
                      type="number"
                      value={coins}
                      onChange={(e) => handleCoinsChange(e.target.value)}
                      variant="outlined"
                      required
                      inputProps={{
                        min: 1,
                        step: 1,
                        className: "text-base font-medium",
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "12px",
                          background: "#fff",
                        },
                      }}
                    />
                  </FormControl>
                </div>
              </div>
            </div>
          </DialogContent>

          <div className="p-4 border-t">
            <div className="flex items-center justify-between mb-3">
              <div className="text-gray-700">
                <p className="text-xs font-medium">Total Amount</p>
                {coins && <p className="text-xs text-gray-500">{coins} coins</p>}
              </div>
              {coins && <p className="text-xl font-bold text-blue-600">₹{calculatePrice(coins)}</p>}
            </div>
            <button
              onClick={handlePayment}
              disabled={!coins || isLoading}
              className="w-full py-2.5 px-4 bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-xl 
            font-semibold transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? "Processing..." : `Pay ₹${coins ? calculatePrice(coins) : "0"}`}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AddCoins;
