import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { signOut } from "firebase/auth";
import { auth } from "lib/firebase/firebase";
import { UserAuth } from "provider/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { licenceTypes } from "lib/utilities/globalConstants";
import brain from "assets/brain.png";
import logo from "assets/logo_blue.svg";
import {
  DashboardFilled,
  DashboardOutline,
  HomeFilled,
  HomeOutline,
} from "components/icons/SvgIcons";
import { Paid, PaidOutlined, PriceChange, PriceChangeOutlined } from "@mui/icons-material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"; // Import Admin Panel Settings Icon

const AdminSidebar = () => {
  const { user } = UserAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = async () => {
    try {
      localStorage.removeItem("accessiblePages");
      await signOut(auth);
      navigate("/signin");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const isPathActive = (path) => location.pathname.startsWith(path);

  const availableLicences = user?.claims?.licences || [];

  const navigationItems = [
    ...availableLicences.map((licence) => ({
      path: `/admin/${licence}/dashboard`,
      icon: isPathActive(`/admin/${licence}/dashboard`) ? (
        <DashboardFilled className="w-5 h-5 mr-3 text-white" size="20px" />
      ) : (
        <DashboardOutline className="w-5 h-5 mr-3 text-gray-900" size="20px" />
      ),
      label: `Dashboard ${licenceTypes[licence]}`,
    })),
  ];

  return (
    <div className="w-52 h-screen bg-[#F9F9F9] flex flex-col fiexd left-0 top-0 shadow-sm border-r">
      <div className="p-4">
        <div className="flex items-center justify-center space-x-2">
          <div className="flex space-x-1">
            <img src={logo} alt="Neusort" width="35px" />
          </div>
          <span className="font-semibold text-xl ml-2">Neusort</span>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto">
        <div className="px-3 py-2">
          <span className="text-gray-500 font-medium text-xs">HOME</span>
          <button
            key={"/admin/dashboard"}
            onClick={() => handleNavigation("/admin/dashboard")}
            className={`w-full flex items-center px-3 py-2 rounded-xl text-sm mb-1
              ${
                isPathActive("/admin/dashboard")
                  ? "text-white bg-gray-900 font-medium hover:bg-gray-900"
                  : "text-gray-800 hover:bg-gray-200"
              }`}
          >
            <div className={`mr-3`}>
              {isPathActive("/admin/dashboard") ? (
                <HomeFilled className="mr-3 text-white" size="20px" />
              ) : (
                <HomeOutline className="mr-3 text-gray-900" size="20px" />
              )}
            </div>
            Home
          </button>
          <span className="text-gray-500 font-medium text-xs pb-1">DASHBOARD</span>
          {navigationItems.map((item) => (
            <button
              key={item.path}
              onClick={() => handleNavigation(item.path)}
              className={`w-full flex items-center px-3 py-2 rounded-xl text-sm mb-1
                ${
                  isPathActive(item.path)
                    ? "text-white bg-gray-900 font-medium hover:bg-gray-900"
                    : "text-gray-800 hover:bg-gray-200"
                }`}
            >
              <div className={`mr-3`}>{item.icon}</div>
              {item.label}
            </button>
          ))}
          <span className="text-gray-500 font-medium text-xs pb-1">OTHER</span>
          <button
            onClick={() => handleNavigation("/admin/transactions")}
            className={`w-full flex items-center px-3 py-2 rounded-xl text-sm mb-1
                ${
                  isPathActive("/admin/transactions")
                    ? "text-white bg-gray-900 font-medium hover:bg-gray-900"
                    : "text-gray-800 hover:bg-gray-200"
                }`}
          >
            <div className={`mr-3`}>
              {isPathActive("/admin/transactions") ? (
                <Paid className="text-white" style={{ width: "20px", height: "20px" }} />
              ) : (
                <PaidOutlined className="text-gray-900" style={{ width: "20px", height: "20px" }} />
              )}
            </div>
            Transactions History
          </button>
          <button
            onClick={() => handleNavigation("/admin/pricing")}
            className={`w-full flex items-center px-3 py-2 rounded-xl text-sm mb-1
                ${
                  isPathActive("/admin/pricing")
                    ? "text-white bg-gray-900 font-medium hover:bg-gray-900"
                    : "text-gray-800 hover:bg-gray-200"
                }`}
          >
            <div className={`mr-3`}>
              {isPathActive("/admin/pricing") ? (
                <PriceChange className="text-white" style={{ width: "20px", height: "20px" }} />
              ) : (
                <PriceChangeOutlined
                  className="text-gray-900"
                  style={{ width: "20px", height: "20px" }}
                />
              )}
            </div>
            Pricing Plans
          </button>

          <button
            onClick={() => handleNavigation("/admin/role-mapping")}
            className={`w-full flex items-center px-3 py-2 rounded-xl text-sm mb-1
                ${
                  isPathActive("/admin/role-mapping")
                    ? "text-white bg-gray-900 font-medium hover:bg-gray-900"
                    : "text-gray-800 hover:bg-gray-200"
                }`}
          >
            <div className={`mr-3`}>
              {isPathActive("/admin/role-mapping") ? (
                <AdminPanelSettingsIcon
                  className="text-white"
                  style={{ width: "20px", height: "20px" }}
                />
              ) : (
                <AdminPanelSettingsIcon
                  className="text-gray-900"
                  style={{ width: "20px", height: "20px" }}
                />
              )}
            </div>
            Admin Role Mapping
          </button>
        </div>
      </div>
      <div className="border-t">
        <div className="p-4">
          <div className="flex items-center space-x-3">
            <div className="w-10 h-10 rounded-full border border-gray-400 bg-gray-100 flex items-center justify-center text-md font-medium text-gray-600">
              {user?.displayName?.[0]}
            </div>
            <div className="min-w-0 flex-1">
              <p className="text-sm font-medium text-gray-900 truncate">{user?.displayName}</p>
              <p className="text-xs text-gray-500 truncate">{user?.email}</p>
            </div>
          </div>
          <button
            onClick={handleLogout}
            className="mt-4 w-full flex items-center justify-center px-4 py-2 text-sm font-medium text-red-600 bg-red-50 hover:bg-red-100 rounded-lg transition-colors duration-200 border border-red-200"
          >
            <span className="mr-2">
              <LogoutIcon className="text-red-600" style={{ width: "18px", height: "18px" }} />
            </span>
            <span>Sign out</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
