const isProduction = process.env.NODE_ENV === 'production';

export const BASE_URL = isProduction ? "https://api.neusort.com/" : "http://localhost:5005/";

export const HOST_NAMES = [
  'neusort.com',
  'hyrr.app',
  'techyrr.com',
  'localhost',
];

export const TEST_IPS = ['20.193.143.183', '20.197.39.7', '20.40.47.123'];

export const adminViewAssignmentDir = "/opt/admin/assignments/"

export const licenceTypes = {
  "v1": "Ultra",
  "v2": "Pro",
}

export const PUBLIC_RAZORPAY_API_KEY = isProduction ? "rzp_live_K5a0N6bOmYFW7O" : "rzp_test_oDrMzpIYkooNTt";

export const restrictedDomains = [
  "mail.com", "gmail.com", "hotmail.com", "yahoo.com",
  "outlook.com", "icloud.com", "aol.com", "zoho.com",
  "protonmail.com", "yandex.com", "live.com", "me.com",
  "msn.com", "gmx.com", "inbox.com"
];

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;