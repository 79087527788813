/* eslint-disable react/prop-types */
/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useEffect, useState } from "react";

// react-router-dom components
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";
import Switch from "@mui/material/Switch";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";

// Material Kit 2 React page layout routes

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Loader from "customComponents/Loader";
import { signOut } from "firebase/auth";
import { auth } from "lib/firebase/firebase";
import { constants } from "lib/utilities/Auth";
import { validateDomain } from "lib/utilities/urlUtilities";
import { AuthContext } from "provider/AuthProvider";
import { toast } from "react-toastify";
import { z } from "zod";
import { BASE_URL } from "lib/utilities/globalConstants";

function SignInBasic({ techyrrAdmin }) {
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const { login, getCustomTokenFromBackend, user } = useContext(AuthContext);
  const { errorCodes } = constants;
  const [queryParameters] = useSearchParams();
  const navigate = useNavigate();
  const { origin } = useParams();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const loginSchema = z.object({
    email: z.string().email(),
    password: z.string().min(6, "Invalid Password"),
  });

  const handleSignin = () => {
    const validationResult = loginSchema.safeParse(credentials);
    if (validationResult.success) {
      login(credentials.email, credentials.password)
        .then(async (res) => {
          const data = await res.user.getIdTokenResult();
          if (!techyrrAdmin && data.claims.admin) {
            navigate("/admin/dashboard"); 
          }
          setLoading(true);
          setCredentials({ email: "", password: "" });

          const adminId = res.user.uid; 
          const token = await res.user.getIdToken();
          
          try {
            const pagesResponse = await fetch(`${BASE_URL}admin/getPagesForAdmin`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({ adminId }), 
            });
  
            if (!pagesResponse.ok) {
              throw new Error("Failed to fetch accessible pages");
            }
  
            const pagesData = await pagesResponse.json();

            localStorage.setItem("accessiblePages", JSON.stringify(pagesData));
          } catch (error) {
            console.error("Error fetching accessible pages:", error);
          }
          const url = document.referrer;
          if (url && validateDomain(url)) {
            const customToken = await getCustomTokenFromBackend(res.user.uid);
            window.parent.postMessage({ token: customToken }, "*");
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(
            <p style={{ fontSize: "16px" }}>
              {errorCodes.firebase[error.code] || error.errorInfo?.message || "Try Again"}
            </p>
          );
        })
        .finally(() => {
          if (!origin) {
            setLoading(false);
          }
        });
    } else {
      toast.error(
        <p style={{ fontSize: "16px" }}>
          {validationResult.error.errors[0].message || "Try Again"}
        </p>
      );
    }
  };

  useEffect(() => {
    if (queryParameters.get("signout") === "true") {
      signOut(auth);
    } else {
      const url = document.referrer;
      if (url && validateDomain(url)) {
        if (user) {
          setLoading(true);
          getCustomTokenFromBackend(user.uid)
            .then(async (token) => {
              window.parent.postMessage({ token: token }, "*");
            })
            .catch((error) => {
              setLoading(false);
              console.error("Error authenticating user:", error);
            })
            .finally(() => {
              if (!origin) {
                setLoading(false);
              }
            });
        }
      }
    }
  }, [user]);

  const handleGotoSignUp = () => {
    if (origin) {
      if (techyrrAdmin) {
        navigate(`/techyrr-admin/signup/${origin}`);
      } else {
        navigate(`/signup/${origin}`);
      }
    } else {
      navigate(`/signup`);
    }
  };

  const handleGotoForgetPassword = () => {
    if (origin) {
      if (techyrrAdmin) {
        navigate(`/techyrr-admin/forgot-password/${origin}`);
      } else {
        navigate(`/forgot-password/${origin}`);
      }
    } else {
      navigate(`/forgot-password`);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox
        px={1}
        pt={10}
        pb={5}
        width="100%"
        height="100vh"
        mx="auto"
        position="relative"
        zIndex={2}
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
          sx={{
            overflowY: "scroll",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
          pt={2}
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white">
                  {origin === "techyrr" ? (techyrrAdmin ? "Recruiter " : "Student ") : ""}Sign in
                </MKTypography>
                {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                  <Grid item xs={2}>
                    <MKTypography component={MuiLink} href="#" variant="body1" color="white">
                      <FacebookIcon color="inherit" />
                    </MKTypography>
                  </Grid>
                  <Grid item xs={2}>
                    <MKTypography component={MuiLink} href="#" variant="body1" color="white">
                      <GitHubIcon color="inherit" />
                    </MKTypography>
                  </Grid>
                  <Grid item xs={2}>
                    <MKTypography component={MuiLink} href="#" variant="body1" color="white">
                      <GoogleIcon color="inherit" />
                    </MKTypography>
                  </Grid>
                </Grid> */}
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                    <MKInput
                      onChange={onChange}
                      name="email"
                      type="email"
                      value={credentials.email}
                      label="Email"
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      onChange={onChange}
                      name="password"
                      type="password"
                      value={credentials.password}
                      label="Password"
                      fullWidth
                    />
                  </MKBox>
                  <MKBox display="flex" alignItems="center" ml={-1}>
                    <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                    <MKTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleSetRememberMe}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Remember me
                    </MKTypography>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="info" fullWidth onClick={handleSignin}>
                      sign in
                    </MKButton>
                  </MKBox>
                  <MKBox mt={3} textAlign="center">
                    <MKTypography variant="button" color="text">
                      Don&apos;t have an account?{" "}
                      <MKTypography
                        onClick={handleGotoSignUp}
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                        sx={{ cursor: "pointer" }}
                      >
                        Sign up
                      </MKTypography>
                    </MKTypography>
                  </MKBox>
                  <MKBox mb={1} textAlign="center">
                    <MKTypography
                      onClick={handleGotoForgetPassword}
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                      sx={{ cursor: "pointer" }}
                    >
                      Forgot Password?
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default SignInBasic;
