import React from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

const ProtectedRoute = ({ component, uniqueName }) => {
  return component;
  // const accessiblePages = JSON.parse(localStorage.getItem("accessiblePages")) || [];

  // if (accessiblePages.length === 0 || accessiblePages.includes(uniqueName)) {
  //   return component;
  // }

  // toast.error("You do not have access to this page", {
  //   position: "top-right",
  //   autoClose: 5000,
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  //   progress: undefined,
  // });

  // return <Navigate to="/not-found" />;
};

export default ProtectedRoute;
