import { CircularProgress } from "@mui/material";
import { signOut } from "firebase/auth";
import { auth } from "lib/firebase/firebase";
import { UserAuth } from "provider/AuthProvider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./admin.css";
import AdminSidebar from "customComponents/AdminComponents/AdminSidebar";
import { tokenResult } from "lib/utilities/tokenResult";
import { BASE_URL } from "lib/utilities/globalConstants";

function AdminLayout({ component }) {
  const [isLoading, setIsLoading] = useState(true);
  const { user, initializing, authorized, setWallet, setExperiencePrice, setCoinPrice, setSkillPrice } = UserAuth();
  const params = new URLSearchParams(window.location.search);
  const inviteId = params.get("inviteId");
  const companyId = params.get("companyId");

  const navigate = useNavigate();

  async function getAdminCoinWallet() {
    try {
      const res = await fetch(
        `${BASE_URL}admin/getAdminCoinWallet`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${(await tokenResult(user)).token}`,
          },
        }
      );
      const { data } = await res.json();
      setWallet({ Coins: data?.numberOfCoins, Hold: data?.coins_hold, Spent: data?.coins_spent });
      setExperiencePrice(data?.experience_pricing);
      setCoinPrice(data?.coin_price);
      setSkillPrice(data?.skill_price);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (initializing) return;
    if (!user) {
      if (inviteId) {
        if (companyId) {
          navigate(`/admin/signup/${inviteId}?companyId=${companyId}`);
        } else {
          navigate(`/admin/signup/${inviteId}`);
        }
      } else navigate("/signin");
      return;
    }
    if (user && authorized) {
      getAdminCoinWallet();
    }
  }, [user, initializing, authorized]);

  if (isLoading)
    return (
      <div className="loading-container">
        <div className="loading-box">
          <h2 className="loading-text">Running Authorization Checks</h2>
          <CircularProgress style={{ height: "40px", width: "40px" }} />
        </div>
      </div>
    );

  return (
    <div className="h-dvh flex">
      {user?.claims?.admin && authorized ? (
        <div className="bg-white flex flex-1 flex-row">
          <AdminSidebar />
          <div className="flex flex-1 flex-col overflow-auto">
            {component}
          </div>
        </div>
      ) : (
        <div className="unauthorized-container">
          <h2 className="unauthorized-heading">You are not authorized.</h2>
          <button
            className="logout-button"
            onClick={async () => {
              await signOut(auth);
              navigate("/signin");
            }}
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
}

export default AdminLayout;
