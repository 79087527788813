import { useParams, useSearchParams } from "react-router-dom";
import AdminSignup from "../../../../pages/Admin/Signup/index";

function AdminSignupPage() {
  const { inviteId } = useParams();
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get("companyId") || null; 

  return (
    <div>
      <AdminSignup inviteId={inviteId} companyId={companyId} />
    </div>
  );
}

export default AdminSignupPage;
