import React from "react";
import { Link } from "react-router-dom";
import { useBreadcrumbs } from "provider/BreadcrumbProvider";
import CoinIcon from '@mui/icons-material/MonetizationOnOutlined';
import HoldIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import SpentIcon from '@mui/icons-material/PaymentOutlined';
import { ChevronRight, Home } from "@mui/icons-material";
import { UserAuth } from "provider/AuthProvider";
import AddCoins from "./AddCoins";

const AdminNavbar = () => {
  const { breadcrumbs } = useBreadcrumbs();
  const { wallet } = UserAuth();

  return (
    <nav className="bg-white border-b border-gray-200 h-11 sticky top-0 z-50 flex justify-between px-3 shadow-sm">
      <ol className="flex items-center">
        <li className="flex items-center">
          <Link
            to="/admin/dashboard"
            className="p-2 rounded-lg text-gray-500 hover:text-blue-600 hover:bg-blue-50 transition-colors duration-200 flex items-center"
            aria-label="Home"
          >
            <Home className="w-[20px] h-[20px]" />
          </Link>
          <ChevronRight className="w-4 h-4 text-gray-700" />
        </li>
        {breadcrumbs?.map((path, index) => {
          const isLast = index === breadcrumbs.length - 1;
          return (
            <li key={index} className="flex items-center min-w-0">
              {!isLast ? (
                <>
                  <Link
                    to={path.link}
                    className="px-2.5 py-1.5 rounded-md text-gray-600 hover:text-blue-600 hover:bg-blue-50 transition-colors duration-200 text-sm font-medium flex items-center"
                  >
                    <span className="truncate max-w-[100px]">
                      {path.label}
                    </span>
                  </Link>
                  <ChevronRight className="w-4 h-4 text-gray-700" />
                </>
              ) : (
                  <span className="px-2.5 py-1.5 bg-gray-50 rounded-md text-gray-900 text-sm font-semibold flex">
                    <span className="truncate max-w-[100px]">
                      {path.label}
                    </span>
                  </span>
              )}
            </li>
          );
        })}
      </ol>
      <div className="flex items-center">
        <div className="flex items-center space-x-3">
          <AddCoins />
          <div className="group bg-emerald-50 border border-emerald-200 hover:bg-emerald-100 cursor-default px-4 py-1.5 rounded-lg flex items-center transition-colors duration-200">
            <CoinIcon
              className="text-emerald-700 mr-2.5 w-[20px] h-[20px] transition-transform group-hover:scale-110"
            />
            <span className="text-emerald-700 text-sm font-medium whitespace-nowrap">
              {(wallet?.Coins || 0).toLocaleString()} Coins
            </span>
          </div>
          <div className="group bg-yellow-50 border border-yellow-300 hover:bg-yellow-100 cursor-default px-4 py-1.5 rounded-lg flex items-center transition-colors duration-200">
            <HoldIcon
              className="text-yellow-700 mr-2.5 w-[20px] h-[20px] transition-transform group-hover:scale-110"
            />
            <span className="text-yellow-700 text-sm font-medium whitespace-nowrap">
              {(wallet?.Hold || 0).toLocaleString()} Hold
            </span>
          </div>
          <div className="group bg-red-50 border border-red-200 hover:bg-red-100 cursor-default px-4 py-1.5 rounded-lg flex items-center transition-colors duration-200">
            <SpentIcon
              className="text-red-600 mr-2.5 w-[20px] h-[20px] transition-transform group-hover:scale-110"
            />
            <span className="text-red-600 text-sm font-medium whitespace-nowrap">
              {(wallet?.Spent || 0).toLocaleString()} Spent
            </span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default AdminNavbar;