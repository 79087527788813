import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotAuthorized = () => {
    const navigate = useNavigate();

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            textAlign="center"
        >
            <Typography variant="h2" color="error" gutterBottom>
                403 - Not Authorized
            </Typography>
            <Typography variant="h5" color="textSecondary" paragraph>
                You do not have the necessary permissions to access this page.
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(-2)} 
                sx={{ mt: 3, fontWeight: "bold", color: "white",}}
            >
                Return to Previous Page
            </Button>
        </Box>
    );
};

export default NotAuthorized;
